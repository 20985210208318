.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@font-face {
  font-family: 'Montserrat';
  src: url("../src/assets/fonts/Montserrat-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: 'NunitoSans';
  src: url("../src/assets/fonts/NunitoSans-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url("../src/assets/fonts/Montserrat-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: 'NunitoSans-Bold';
  src: url("../src/assets/fonts/NunitoSans-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-Light';
  src: url("../src/assets/fonts/Montserrat-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: 'NunitoSans-Light';
  src: url("../src/assets/fonts/NunitoSans-Light.ttf");
  font-display: swap;
}

@font-face {
    font-family: 'Montserrat-ExtraBold';
    src: url("../src/assets/fonts/Montserrat-ExtraBold.ttf");
    font-display: swap;
}

@font-face {
  font-family: 'NunitoSans-ExtraBold';
  src: url("../src/assets/fonts/NunitoSans-ExtraBold.ttf");
  font-display: swap;
}
